<template>
  <section-slider
    :title="translations.certificatesCertificatesSliderTitle"
    :gallery="translations.certificatesGallery"
    :titled="true"
  />
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_CERTIFICATES } from '@/views/certificates/Index.vue'
  import SectionSlider from '@/views/sections/reusable/Slider'

  export default {
    name: 'SectionCertificatesSlider',
    components: {
      SectionSlider,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CERTIFICATES, [
        'translations',
        'language',
      ]),
    },
  }
</script>
